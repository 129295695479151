import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

// @ts-expect-error
import Video from "../../videos/video.mp4";

import ButtonNeon from "../../components/ButtonNeon";
import Translator from "../../components/I18n/Translator";

import * as Styles from "./styles";

const HomeSection: React.FC = () => {
  AOS.init();

  return (
    <>
      <Styles.HeroContainer id="home">
        <Styles.HeroBg>
          <Styles.VideoBg autoPlay loop muted src={Video} type="video/mp4" />
        </Styles.HeroBg>

        <Styles.HeroContent>
          <Styles.HeroH1
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            data-aos-delay="2000"
          >
            {Translator("home.title")}
          </Styles.HeroH1>

          <Styles.HeroP
            data-aos="zoom-in-down"
            data-aos-duration="1000"
            data-aos-delay="2000"
          >
            {Translator("home.subTitle")}
          </Styles.HeroP>

          <Styles.HeroBtnWrapper
            data-aos="flip-up"
            data-aos-duration="1000"
            data-aos-delay="2000"
          >
            <ButtonNeon text={Translator("home.button")} to="services" />
          </Styles.HeroBtnWrapper>
        </Styles.HeroContent>
      </Styles.HeroContainer>
    </>
  );
};

export default HomeSection;
